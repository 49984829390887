import React from 'react'
import Iframe from 'react-iframe'
import { Container, Title, FrameContainer, BackButton, Wrapper } from './styles'

const Materials = () => (
  <Container>
    <Wrapper>
      <BackButton to="/">
        <Title> {'<'} </Title>
      </BackButton>
      <Title>MATERIALS</Title>
    </Wrapper>
    <FrameContainer>
      <Iframe
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTjf7jzkPjFsEjHmNsQ5sYN2ZnJF9vxaAIRSw_wjPXMD1azBbyEr_Pe7ByXtZw26hTivf1LVjIMUoRl/pubhtml?widget=true&amp;headers=false"
        width="90%"
        height="700px"
        align="middle"
      />
    </FrameContainer>
  </Container>
)
export default Materials
