import styled from 'styled-components'
import theme from '../../theme'

export const CopyrightWrapper = styled.div`
  max-width: 450px;
  padding: 0 20px;
  padding-bottom: 100px;
`

export const CopyrightText = styled.p`
  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.body.weight};
  font-size: 14px;
  color: ${theme.colors.fonts.primary};
  text-align: center;

  @media only screen and (max-width: 415px) {
    font-size: 12px;
  }
`

export const CopyrightLink = styled.a`
  font-family: ${theme.fonts.body.family};
  font-weight: ${theme.fonts.body.weight};
  font-size: 14px;
  color: ${theme.colors.fonts.accent};
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 415px) {
    font-size: 12px;
  }
`
