import React from 'react'
import Copyright from '../components/Copyright'
import { PageContainer } from '../components/pageStyles'
import '../reset.css'
import Materials from '../components/Materials'

const MaterialsPage = () => (
  <PageContainer>
    <Materials />
    <Copyright />
  </PageContainer>
)

export default MaterialsPage
