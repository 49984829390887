import styled from 'styled-components'
import { Link } from 'gatsby'
import theme from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  padding-bottom: 40px;
  width: 100%;
  min-height: 700px;
  @media only screen and (max-width: 1060px) {
    align-items: center;
  }
`
export const Title = styled.h1`
  left: 0.5em;
  font-family: ${theme.fonts.bold.family};
  font-weight: ${theme.fonts.bold.weight};
  font-size: 6vw;
  letter-spacing: 0.3em;
  font-style: italic;
  color: ${theme.colors.fonts.primary};
`
export const FrameContainer = styled.div`
  display: flex;
  align-content: center;
  width: 100%;
  align-items: center;
  justify-content: center;
`
export const BackButton = styled(Link)`
  :active {
    text-decoration-color: transparent;
    text-decoration: none;
  }
  :hover {
    text-decoration: none;
  }
  text-decoration: none;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`
